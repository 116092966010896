import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { REQUEST_TIMEOUT } from './constants';
import { deleteAllCookies } from './cookies';
import errorMessages, { errorCodes } from './errorTypes';
import {
	clearAllLocalStorage,
	getValueFromLocalStorage,
} from './local-storage';

const headers: { Authorization: string | any } = {
	Authorization: `Bearer ${getValueFromLocalStorage('token')}`,
};

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers,
	timeout: REQUEST_TIMEOUT,
});

const redirectToLogin = () => {
	window.location.href = '/login';
	deleteAllCookies();
	clearAllLocalStorage();
};

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (
			errorMessages.includes(error?.response?.data?.message) &&
			errorCodes.includes(error?.response?.status)
		) {
			setTimeout(redirectToLogin, 500);
		}
		return Promise.reject(error);
	}
);

const useAxios = makeUseAxios({
	axios: instance,
});

export default useAxios;
