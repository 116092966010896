import React, { lazy, Suspense } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import { NetworkStatusProvider } from 'contexts/network-status-context';
import NotFound from 'pages/NotFound';
import Login from 'pages/Login';
import NotificationProvider from 'contexts/notification-context';
import { getUserPermissions } from 'contexts/permission-context';
import { fetchPermittedRoute } from 'utils/helper';
import { getValueFromLocalStorage } from 'utils/local-storage';
import PrivateRoutes from './PrivateRoutes';

const Application = lazy(() => import('pages/Application'));

export default function OroPaisaRoutes() {
	const userPermissions = getUserPermissions();
	return (
		<NetworkStatusProvider>
			<NotificationProvider>
				<Router>
					<Suspense>
						<Routes>
							<Route
								path='/'
								element={
									getValueFromLocalStorage('token') ? (
										<PrivateRoutes>
											<Navigate to={fetchPermittedRoute(userPermissions)} />
										</PrivateRoutes>
									) : (
										<Navigate to='/login' />
									)
								}
							/>
							<Route path='/login' element={<Login />} />
							<Route
								path={'/*'}
								element={
									<PrivateRoutes>
										<Application />
									</PrivateRoutes>
								}
							/>
							<Route path='/page-not-found' element={<NotFound />} />
							<Route path='*' element={<Navigate to='/page-not-found' />} />
						</Routes>
					</Suspense>
				</Router>
			</NotificationProvider>
		</NetworkStatusProvider>
	);
}
