import { useEffect, useState } from 'react';
import useAxios from 'utils/initAxios';

export default function useVerifyGoogleToken({ token }: { token: string }) {
	const [user, setUser] = useState();
	const [{ data, error }, execute] = useAxios(
		{
			baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
			url: '/api/v1/auth/verify-google',
			method: 'POST',
			headers: {
				Authorization: `JWT ${token}`,
				'client-id': process.env.REACT_APP_CLIENT_ID || '',
			},
		},
		{ manual: true }
	);

	useEffect(() => {
		if (data) {
			setUser(data);
		}
	}, [data]);

	return { user, error, handleLogin: execute };
}
