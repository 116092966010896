const permissions = {
	loans: {
		permission: ['VIEW_LEDGER_LOAN'],
		export: {
			permission: ['EXPORT_LEDGER_LOAN'],
		},
		table_row: {
			permission: ['VIEW_LEDGER_LOAN_DETAIL'],
			export_loan: {
				permission: ['EXPORT_LEDGER_LOAN'],
			},
			pledge_receipt: {
				permission: ['DOWNLOAD_PLEDGE_CARD'],
			},
			record_manual_payment: {
				permission: ['CREATE_MANUAL_PAYMENT'],
			},
			export_payment_ledger: {
				permission: ['EXPORT_PAYMENT_LEDGER'],
			},
			export_gl_ledger: {
				permission: ['EXPORT_GL_LEDGER'],
			},
			export_sl_ledger: {
				permission: ['EXPORT_SL_LEDGER'],
			},
			reinitiate_failed_payout: {
				permission: ['REINITIATE_FAILED_LOAN_PAYOUT'],
			},
		},
	},
	brl_loans: {
		permission: ['VIEW_LEDGER_BRL_LOAN'],
		export: {
			permission: ['EXPORT_LEDGER_BRL_LOAN'],
		},
		table_row: {
			permission: ['VIEW_LEDGER_BRL_LOAN_DETAIL'],
			export_loan: {
				permission: ['EXPORT_LEDGER_BRL_LOAN'],
			},
			view_documents: {
				permission: ['VIEW_LEDGER_BRL_DOC'],
			},
			export_payment_ledger: {
				permission: ['EXPORT_LEDGER_BRL_LEDGER'],
			},
		},
	},
	payments: {
		permission: ['VIEW_LEDGER_PAYMENT'],
		export: {
			permission: ['EXPORT_LEDGER_PAYMENT'],
		},
		reinitiate_failed_payout: {
			permission: ['REINITIATE_FAILED_PAYOUT'],
		},
	},
	refunds: {
		permission: ['VIEW_LEDGER_REFUND'],
	},
	fund_transfer: {
		permission: ['VIEW_LEDGER_FUND_TRANSFER'],
		export: {
			permission: ['EXPORT_LEDGER_FUND_TRANSFER'],
		},
	},
	payout: {
		permission: ['VIEW_LEDGER_PAYOUT'],
		table_row: {
			reinitiate: { permission: ['REINITIATE_FAILED_PAYOUT'] },
			refresh: { permission: ['VIEW_PAYMENT'] },
			cancel: { permission: ['DELETE_PAYMENT'] },
		},
		activate_payout: {
			permission: ['TOGGLE_LEDGER_PAYOUT'],
		},
	},
	config: {
		reports: {
			permission: ['VIEW_REPORT_LOG'],
			generate_report: ['CREATE_AUM_REPORT'],
			table_row: {
				download_file: { permission: ['VIEW_AUM_REPORT'] },
			},
		},
		recon: {
			permission: ['VIEW_RECON_LOG'],
			upload_bank_statement: ['UPLOAD_LEDGER_BANK_STATEMENT'],
			table_row: {
				download_file: { permission: ['DOWNLOAD_RECON'] },
			},
		},
		fetchClosure: {
			permission: ['VIEW_LEDGER_LOG'],
			fetch_closure_amount: ['FETCH_CLOSURE_AMOUNT'],
		},
	},
	other: {
		permission: [],
	},
};

export default permissions;
