import React from 'react';
import { ISvgProps } from './interface';

export default function EmptyStateIllustrationsSvg({
	width = '260',
	height = '188',
	className,
}: ISvgProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 260 188'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				d='M111.076 185.825C111.076 185.825 0.916557 204.58 6.34617 92.5311C11.7758 -19.5175 118.494 -1.88606 143.268 15.2288C173.383 36.0325 182.353 31.7755 208.271 53.3984C248.746 87.1691 236.066 164.12 111.076 185.825Z'
				fill='#EBF6FF'
			/>
			<path
				d='M259.021 159.854C259.021 163.162 244.81 166.076 223.276 167.769C211.804 168.672 198.254 169.228 183.668 169.315C182.341 169.324 181.007 169.328 179.665 169.327C177.381 169.327 175.12 169.316 172.883 169.293C162.547 169.189 152.776 168.847 143.95 168.315C118.063 166.755 100.309 163.552 100.309 159.854C100.309 156.049 119.119 152.764 146.249 151.259C150.73 151.009 155.44 150.81 160.327 150.667C162.803 150.594 165.323 150.534 167.887 150.488C171.73 150.418 175.656 150.384 179.664 150.384C194.242 150.384 207.903 150.852 219.641 151.672C243.196 153.312 259.021 156.362 259.021 159.854Z'
				fill='black'
			/>
			<path
				d='M223.646 167.389L223.276 167.502C211.804 168.405 198.254 168.961 183.668 169.047C179.004 161.767 173.462 157.023 173.462 157.023L172.883 169.029C162.546 168.925 152.775 168.583 143.949 168.051L142.997 154.993C142.997 154.993 144.189 153.471 146.249 150.995C156.902 138.192 190.778 99.9513 202.995 115.739L203.053 115.817C203.99 117.028 204.889 118.298 205.753 119.612L205.759 119.621C206.563 120.844 207.338 122.102 208.083 123.395C213.319 132.456 217.111 142.944 219.641 151.408C222.387 160.586 223.646 167.389 223.646 167.389Z'
				fill='#4F4F4F'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M202.91 120.966C202.91 120.966 219.574 150.439 217.435 167.592L223.344 167.254C223.344 167.254 216.694 135.019 204.889 118.936L202.91 120.966Z'
				fill='white'
			/>
			<path
				d='M195.957 136.144C195.957 136.144 203.297 141.85 214.747 137.561'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M198.56 125.86C198.56 125.86 191.396 148.042 143.351 159.854L142.996 150.381L198.56 125.86Z'
				fill='black'
			/>
			<path
				d='M58.0171 151.481C58.0171 151.481 57.4929 152.74 52.453 154.449C46.6734 156.409 42.9203 156.422 43.1638 157.013C43.4073 157.604 44.4608 158.047 44.4608 158.047C44.4608 158.047 42.7901 158.597 42.1729 158.638C41.5557 158.68 42.5441 160.685 44.383 160.154C44.383 160.154 42.5069 160.915 42.1628 160.963C41.8187 161.012 41.0375 161.691 41.8567 162.248C42.676 162.804 43.792 162.451 43.792 162.451C43.792 162.451 42.4393 163.211 43.2374 163.602C44.0355 163.993 45.4238 164.146 54.6766 160.985C54.6766 160.985 57.0524 159.802 57.1378 160.456C57.2232 161.111 56.1232 162.539 54.5946 163.527C53.066 164.515 54.0484 165.382 56.4639 164.817C58.8794 164.252 63.1431 160.707 64.0064 157.151C64.8696 153.595 58.0171 151.481 58.0171 151.481Z'
				fill='white'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M58.8486 154.216C58.8486 154.216 59.8345 156.968 60.9826 157.979C62.1308 158.99 61.1906 161.735 61.1906 161.735C61.1906 161.735 63.9155 158.819 64.0652 156.248C64.2148 153.677 58.8486 154.216 58.8486 154.216Z'
				fill='black'
			/>
			<path
				d='M43.792 162.448C43.792 162.448 49.4321 160.217 51.1146 159.125'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M44.3828 160.152C44.3828 160.152 47.6387 158.875 50.2098 157.439'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M44.4604 158.046C44.4604 158.046 47.5887 157.37 49.2061 156.43'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M59.395 147.588L56.7241 151.588C56.7241 151.588 62.3727 159.561 65.9059 158.352L70.5061 153.559L59.395 147.588Z'
				fill='#478AE2'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M82.7012 54.4107C82.7012 54.4107 84.858 61.2151 87.643 63.9476C87.643 63.9476 86.7645 66.7909 88.5873 67.1866C89.6653 67.4208 91.3224 66.3893 91.3224 66.3893C91.3224 66.3893 95.0248 70.3825 99.8803 68.0574C101.734 67.1697 102.484 66.4181 102.734 66.1129C102.745 66.0991 102.76 66.0886 102.777 66.0824C102.793 66.0762 102.811 66.0744 102.829 66.0773C102.847 66.0802 102.863 66.0877 102.877 66.099C102.891 66.1103 102.902 66.125 102.908 66.1416L106.769 75.8865C106.769 75.8865 109.71 83.3596 112.118 81.2908C115.096 78.7341 121.329 65.3426 123.184 61.8711L107.169 51.5682C107.169 51.5682 110.564 47.0027 108.083 43.6377C105.602 40.2727 102.005 40.9728 101.307 42.7322C100.61 44.4916 98.8826 38.3696 93.2324 41.1131C87.5821 43.8567 82.7012 54.4107 82.7012 54.4107Z'
				fill='white'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M94.2471 63.3184C94.2471 63.3184 92.9172 65.3771 91.3252 66.3891L94.2471 63.3184Z'
				fill='white'
			/>
			<path
				d='M94.2471 63.3184C94.2471 63.3184 92.9172 65.3771 91.3252 66.3891'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M92.951 44.5069C92.951 44.5069 89.1235 56.5972 76.5142 54.8327C69.9026 53.9027 74.6989 38.439 83.518 34.4847C85.6562 33.5268 88.1216 33.854 90.0434 35.1932C91.0943 35.9254 92.7489 36.0751 94.8981 33.0703C97.202 29.8456 100.105 32.4835 102.202 36.4065C104.283 40.2999 104.494 41.3001 104.494 41.3001C104.494 41.3001 100.165 40.8773 101.548 47.0138C102.551 51.4686 101.155 52.4873 99.8745 51.6503C98.5936 50.8133 94.3164 41.5706 92.951 44.5069Z'
				fill='black'
			/>
			<path
				d='M87.6432 63.9474C88.1079 62.5702 88.0477 61.0699 87.4741 59.7344L87.6432 63.9474Z'
				fill='white'
			/>
			<path
				d='M87.6432 63.9474C88.1079 62.5702 88.0477 61.0699 87.4741 59.7344'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M104.7 46.9433C104.7 46.9433 105.207 45.1382 104.972 44.0771'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M103.435 43.6965C103.435 43.5883 105.531 43.3922 106.535 45.5929'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M102.556 65.8886C103.566 64.9828 104.497 63.9928 105.339 62.9295C105.402 62.8514 105.488 62.7956 105.585 62.7706C105.683 62.7456 105.785 62.7526 105.878 62.7905C105.971 62.8285 106.049 62.8954 106.101 62.9814C106.153 63.0674 106.176 63.1677 106.166 63.2677C105.935 65.4718 105.574 69.4109 105.522 72.7369L102.556 65.8886Z'
				fill='black'
			/>
			<path
				d='M86.8488 63.7929C88.1569 62.7268 88.3036 60.7414 87.1764 59.3583C86.0493 57.9753 84.0751 57.7184 82.767 58.7845C81.4589 59.8506 81.3122 61.836 82.4394 63.219C83.5665 64.6021 85.5407 64.859 86.8488 63.7929Z'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M94.8742 59.2402C96.1823 58.1741 96.329 56.1886 95.2018 54.8056C94.0747 53.4226 92.1005 53.1656 90.7924 54.2317C89.4843 55.2979 89.3376 57.2833 90.4648 58.6663C91.5919 60.0494 93.5661 60.3063 94.8742 59.2402Z'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M93.3623 58.5479C93.7288 58.4535 93.9195 57.9646 93.7884 57.4559C93.6573 56.9473 93.254 56.6115 92.8876 56.706C92.5212 56.8004 92.3304 57.2893 92.4615 57.7979C92.5926 58.3066 92.9959 58.6424 93.3623 58.5479Z'
				fill='#191919'
			/>
			<path
				d='M82.0991 57.0011C82.0991 57.0011 83.626 57.0315 84.3202 55.2256'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M99.6865 63.167C99.6865 63.167 96.5016 63.342 95.0508 67.4873'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M89.0332 51.65C89.0332 51.65 90.227 52.6045 91.8791 51.5908'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M122.2 59.4225C122.2 59.4225 148.535 57.1397 175.563 79.8229C202.592 102.506 205.982 116.999 205.982 116.999C205.982 116.999 180.485 154.187 137.324 155.354L123.084 131.497C123.084 131.497 113.153 128.938 104.653 120.966C104.653 120.966 83.6236 146.617 70.0394 155.986C70.0394 155.986 64.8043 155.925 58.2197 147.452C58.2197 147.452 81.0474 114.471 88.4504 98.3201C95.8533 82.169 98.9917 74.7872 101.893 74.1582C104.795 73.5292 111.374 76.8637 111.374 76.8637L122.2 59.4225Z'
				fill='#478AE2'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M102.041 104.463C102.041 104.463 98.9412 101.384 98.1794 98.4141C97.4177 95.444 94.2539 87.4366 88.5909 91.0814C88.5909 91.0814 88.1175 91.1575 88.5749 91.8026C89.0323 92.4477 90.6446 93.0395 90.3233 93.5375C90.002 94.0355 88.4371 95.4026 87.3836 94.3238C86.3301 93.245 86.2845 92.5593 85.6436 92.8636C85.0028 93.168 83.3194 94.1225 83.6872 95.3468C84.055 96.571 86.3716 101.384 87.8596 102.614C89.3476 103.843 96.3583 108.278 97.1006 109.508C97.8429 110.737 102.041 104.463 102.041 104.463Z'
				fill='white'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M102.09 109.602L105.416 113.771L101.247 110.127L98.1547 112.901L95.0425 109.154C95.0425 109.154 101.367 104.386 101.933 102.528L106.837 105.074C105.595 106.996 102.09 109.602 102.09 109.602Z'
				fill='#478AE2'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M106.838 105.077C106.995 104.846 107.12 104.595 107.209 104.33C107.209 104.33 120.091 108.618 129.14 109.612C129.14 109.612 115.399 85.1784 123.475 59.498'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M98.1547 112.901L98.0532 112.992C100.38 116.504 104.648 120.965 104.648 120.965'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M129.139 109.609C129.139 109.609 134.642 109.491 140.258 113.734C140.258 113.734 142.972 115.453 138.242 109.103C133.511 102.752 128.622 107.182 124.326 97.8418C124.326 97.8418 126.521 104.496 129.139 109.609Z'
				fill='black'
			/>
			<path
				d='M123.084 131.497C123.084 131.497 141.576 139.656 148.496 124.064C155.416 108.473 155.67 88.5274 155.67 88.5274C155.67 88.5274 155.977 86.1229 156.262 93.0549C156.547 99.9869 156.087 111.02 156.822 114.138C158.519 121.343 150.424 140.696 123.084 131.497Z'
				fill='black'
			/>
			<path
				d='M101.743 93.4688L109.33 105.003'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M155.611 152.621L140.899 132.151'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M111.372 76.8594C111.372 76.8594 118.86 95.9273 127.629 109.418'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M165.96 116.759C165.96 116.759 179.384 121.392 187.123 114.899C187.123 114.899 187.448 113.728 185.844 113.942C184.239 114.156 181.955 116.747 177.206 115.698C172.457 114.65 169.645 115.904 165.96 116.759Z'
				fill='black'
			/>
			<path
				d='M89.6394 92.2661L85.7798 87.6609C85.6903 87.554 85.5638 87.4849 85.4255 87.4673C85.2872 87.4497 85.1474 87.4851 85.0341 87.5662L83.5266 88.6433C83.4653 88.6871 83.4135 88.7429 83.3746 88.8074C83.3356 88.8719 83.3102 88.9437 83.3 89.0184C83.2898 89.0931 83.295 89.169 83.3152 89.2416C83.3354 89.3142 83.3702 89.3819 83.4175 89.4406L87.7573 94.8118C87.7573 94.8118 90.9591 94.5269 89.6394 92.2661Z'
				fill='black'
			/>
			<path
				d='M83.5274 85.4424L85.7975 88.5816L84.2697 89.6461L82.0225 86.7241L83.5274 85.4424Z'
				fill='black'
			/>
			<path
				d='M83.3105 85.603C85.165 84.0905 84.6669 80.4103 82.1979 77.383C79.7289 74.3558 76.2241 73.1278 74.3696 74.6403C72.5151 76.1528 73.0132 79.833 75.4822 82.8603C77.9512 85.8875 81.456 87.1155 83.3105 85.603Z'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M82.879 85.0736C84.4412 83.7996 83.8993 80.5497 81.6688 77.8148C79.4383 75.0799 76.3637 73.8957 74.8016 75.1697C73.2395 76.4438 73.7813 79.6936 76.0119 82.4285C78.2424 85.1634 81.3169 86.3477 82.879 85.0736Z'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M74.5703 75.373C74.5703 75.373 81.0094 75.5785 82.6344 82.9079C82.7498 83.4136 82.7598 83.9377 82.664 84.4475C82.6046 84.7859 82.4787 85.1092 82.2937 85.3986'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M75.0727 74.1582C75.0727 74.1582 72.0916 74.8904 72.429 78.5496C72.7663 82.2088 75.8785 85.523 78.6009 86.5367C78.6009 86.5367 82.0208 87.6654 83.7439 85.1409'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
			/>
			<path
				d='M94.8467 54.4444L101.375 48.5625'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M119.419 58.4648L111.372 76.8588C111.372 76.8588 116.247 72.902 121.086 75.0064L123.641 58.3887L119.419 58.4648Z'
				fill='black'
			/>
			<path
				d='M103.23 71.0381L111.372 76.8592C111.372 76.8592 107.022 77.9583 106.108 82.3336L99.5981 74.4166L103.23 71.0381Z'
				fill='black'
			/>
			<path
				d='M123.084 131.496C123.084 131.496 143.876 141.885 150.556 118.774C157.235 95.6629 155.673 88.5254 155.673 88.5254'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M121.768 25.0959L121.899 24.6377C122.134 23.6933 122.824 22.7776 124.055 22.0894C125.169 21.4519 125.845 20.943 126.131 20.1736C126.456 19.3002 126.125 18.5148 125.053 18.0971C124.438 17.868 123.676 17.8181 123.127 17.9847L123.118 16.7537C123.853 16.5592 124.913 16.6421 125.824 16.9794C127.791 17.7116 128.229 19.2579 127.745 20.5583C127.312 21.7217 126.344 22.3338 125.166 22.989C124.082 23.5986 123.521 24.2665 123.248 25.1585L123.106 25.5922L121.768 25.0959ZM121.091 28.7991C120.51 28.5818 120.27 27.968 120.494 27.3686C120.73 26.7361 121.322 26.4495 121.922 26.6727C122.521 26.8959 122.767 27.4937 122.53 28.1261C122.307 28.7255 121.742 29.0409 121.108 28.8025L121.091 28.7991Z'
				fill='black'
			/>
			<path
				d='M93.7558 20.5495V20.0735C93.7296 19.1004 94.1506 18.0368 95.1525 17.0416C96.0546 16.1294 96.5704 15.4589 96.6405 14.6405C96.72 13.7105 96.1916 13.0443 95.0468 12.9276C94.3924 12.8718 93.6459 13.0274 93.1614 13.3343L92.8232 12.1506C93.4793 11.7668 94.5226 11.563 95.4865 11.6433C97.579 11.8226 98.4143 13.1956 98.2951 14.5779C98.1894 15.8149 97.4201 16.6654 96.4596 17.6098C95.5778 18.4866 95.2168 19.2805 95.1914 20.213L95.1703 20.6687L93.7558 20.5495ZM94.094 24.2992C93.4742 24.2459 93.0794 23.7175 93.1352 23.08C93.1927 22.4078 93.6882 21.9733 94.3248 22.0282C94.9614 22.0832 95.358 22.5939 95.2996 23.266C95.2455 23.9026 94.7847 24.3584 94.116 24.3L94.094 24.2992Z'
				fill='black'
			/>
			<path
				d='M64.7767 34.2983L64.4106 33.9931C63.6412 33.4013 63.084 32.3986 62.9479 30.9934C62.8126 29.7167 62.6198 28.8933 62.0306 28.3209C61.3626 27.6715 60.5087 27.658 59.6962 28.4705C59.238 28.9414 58.8854 29.6187 58.8169 30.1877L57.6865 29.6998C57.8049 28.9491 58.3071 28.0089 58.9818 27.319C60.4453 25.8124 62.0365 26.0347 63.0316 27.0019C63.9219 27.8668 64.0935 29.0006 64.2153 30.3415C64.3362 31.5793 64.7225 32.3614 65.4277 32.971L65.7658 33.2762L64.7767 34.2983ZM67.8931 36.412C67.4602 36.8576 66.8015 36.8288 66.3433 36.3833C65.8588 35.9123 65.836 35.2546 66.2824 34.7955C66.7288 34.3364 67.3731 34.3541 67.8575 34.8251C68.3158 35.2698 68.3767 35.9149 67.9057 36.3993L67.8931 36.412Z'
				fill='black'
			/>
			<path
				d='M66.7734 169.551H84.6129'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M1 161.735H5.75831'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M15.9536 169.091H31.6523'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M27.2847 162.447H32.043'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M66.7734 162.78H71.5317'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M5.75845 128.351C5.75845 128.351 -18.7585 14.492 71.274 1'
				stroke='black'
				strokeWidth='0.3'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
		</svg>
	);
}
