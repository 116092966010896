import Cookies from 'js-cookie';

export function addCookie(key: string, value: string) {
	const cookieExpiryTime = new Date();
	cookieExpiryTime.setTime(cookieExpiryTime.getTime() + 24 * 3600 * 1000); // expires in 24 hours
	Cookies.set(key, value, {
		expires: cookieExpiryTime,
	});
}

export function getValueFromCookie(key: string) {
	return Cookies.get(key);
}

export function removeCookie(key: string) {
	Cookies.remove(key);
}

export function deleteAllCookies() {
	const cookies = document.cookie.split(';');
	cookies.map((cookie) => {
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
		Cookies.remove(name.trim());
		return null;
	});
}
