import { getUserPermissions } from 'contexts/permission-context';
import { IAccessCheckProps } from './interface';

export function intersect(a: String[], b: String[]) {
	const filtered = a?.filter(Set.prototype.has, new Set(b));
	return Array.from(new Set(filtered));
}

export default function AccessCheck({
	children,
	requiredPermissions,
	alternateChildren,
	looseCheck,
}: IAccessCheckProps): JSX.Element {
	const userPermissions = getUserPermissions();
	if (requiredPermissions.length === 0) return children;
	const intersectionLength = intersect(
		userPermissions,
		requiredPermissions
	)?.length;
	const allowed = looseCheck
		? intersectionLength > 0 // if any permission matches
		: intersectionLength === requiredPermissions.length; // if all permissions are given to user
	return allowed ? children : alternateChildren;
}
