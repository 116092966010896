const PING_RESOURCE_URL = '/robots.txt';
const NETWORK_CHECK_POLLING_TIME = 10000;
const REQUEST_TIMEOUT = 50000;

export { PING_RESOURCE_URL, NETWORK_CHECK_POLLING_TIME, REQUEST_TIMEOUT };

export const loanTypes = {
	TAKEOVER: 'TAKEOVER',
	FRESH: 'FRESH',
	GL: 'Gold Loan',
	SL: 'Support Loan',
};

export const FEES_TYPE = {
	FSDC: 'fsdc',
};
