import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import verifyToken from 'services/auth/verifyToken';
import {
	addToLocalStorage,
	clearAllLocalStorage,
	getValueFromLocalStorage,
} from 'utils/local-storage';
import { deleteAllCookies } from '../utils/cookies';
import { IPrivateRoutesProps } from './interface';

export default function PrivateRoutes({
	children,
}: IPrivateRoutesProps): JSX.Element {
	const [isVerified, setIsVerified] = useState(
		!!getValueFromLocalStorage('token')
	);
	const { handleFetchVerifyToken } = verifyToken();

	useEffect(() => {
		handleFetchVerifyToken()
			.then((res) => {
				if (!getValueFromLocalStorage('userPermissions')) {
					const userPermissions = res?.data?.user?.permissions;
					addToLocalStorage('userPermissions', userPermissions);
					window.location.reload();
				}
				setIsVerified(true);
			})
			.catch(() => {
				setIsVerified(false);
				deleteAllCookies();
				clearAllLocalStorage();
			});
	}, [children]);

	// * fetch token from localstorage
	// const TOKEN = getValueFromLocalStorage("token");

	/**
	 * ? check if token and user data exist then render the component
	 * ? else redirect to login
	 */
	return isVerified ? children : <Navigate to={{ pathname: '/login' }} />;
}
