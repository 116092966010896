export default class Utils {
	static addCommaToCurrency(curreny_value: number) {
		const roundedValue = Math.round(curreny_value);
		return roundedValue.toLocaleString('en-IN');
	}

	static debounce(fn: () => void, delay: number) {
		const timer = setTimeout(fn, delay);
		return timer;
	}

	static capitalizeFirstLetter(string: string) {
		return string
			?.toLowerCase()
			?.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}
}
