import React, { useEffect, useState } from 'react';
import { ERROR, SUCCESS } from 'constants/common/notificationTypes';

// ASSETS
import CrossIconSvg from 'assets/svg/crossIconSvg';
import WarningIconSvg from 'assets/svg/warningIconSvg';
import DoneStatus from 'assets/svg/doneStatus';

import { INotificationProps } from './interface';

export default function Notification({
	type,
	message,
	click,
}: INotificationProps) {
	// STATES
	const [dismissToast, setDismissToast] = useState(false);

	// use this effect to auto slide the toast before disappearing
	useEffect(() => {
		setTimeout(() => {
			setDismissToast(true);
		}, 4500);
	}, []);

	// use this function to get the notification icon
	const renderNotificationIcon = () => {
		if (type === ERROR) {
			return (
				<div className='w-[25px] h-[25px] flex items-center justify-center rounded-full bg-primary-red'>
					<CrossIconSvg width='10' height='10' crossFill='#fff' />
				</div>
			);
		}
		if (type === SUCCESS) {
			return <DoneStatus width='25' height='25' />;
		}
		return <WarningIconSvg width='25' height='25' />;
	};

	return (
		<div
			className={`flex items-stretch rounded-[8px] bg-white border-[0.5px] border-secondary-grey ${
				dismissToast
					? 'animate-slideOut translate-x-[500px]'
					: 'animate-slideIn'
			}`}
		>
			<div className='px-5 flex items-center'>{renderNotificationIcon()}</div>
			<div className='p-3 grow min-h-[60px] overflow-auto'>
				<p className='text-base font-semibold leading-7 h-full flex items-center'>
					{message}
				</p>
			</div>
			<div
				role='presentation'
				className='px-7 flex items-center rounded-r-[8px] cursor-pointer bg-secondary-grey'
				onClick={() => {
					setDismissToast(true);
					click();
				}}
			>
				<CrossIconSvg className='h-3 w-3 cursor-pointer' crossFill='#fff' />
			</div>
		</div>
	);
}
