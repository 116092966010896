/* eslint-disable no-restricted-syntax */
import { intersect } from 'routes/AccessCheck';
import { PING_RESOURCE_URL, REQUEST_TIMEOUT } from './constants';
import permissions from './permissionConfig';

export const timeoutUnresolvedRequest = (
	time: number,
	promise: Promise<unknown>
) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			reject(new Error('Request timed out.'));
		}, time);

		promise.then(resolve, reject);
	});
};

export const checkInternetConnection = async () => {
	const controller = new AbortController();
	const { signal } = controller;

	if (!navigator?.onLine) {
		return false;
	}

	try {
		await timeoutUnresolvedRequest(
			REQUEST_TIMEOUT,
			fetch(PING_RESOURCE_URL, {
				method: 'GET',
				signal,
			})
		);

		return true;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);
		controller.abort();
	}

	return false;
};

export const delay = (time: number) =>
	new Promise((resolve) => {
		setTimeout(resolve, time);
	});

export const getS3UrlFromSignedUrl = (signedUrl: string) => {
	const url = signedUrl?.split('?')?.[0];
	return url;
};

export function roundOffNumberToTwoDecimal(value: number) {
	return Number.isInteger(value) ? value : value.toFixed(2);
}

export function commaSeperated(value: number) {
	return value?.toLocaleString('en-IN');
}

export function roundUp(value: number) {
	return Math.ceil(value);
}

// this check returns true if component is allowed to current user permissions otherwise false
export const checkIfComponentHasAccess = (
	userPermissions: string[] = [],
	componentPermissions: string[] = [],
	looseCheck: boolean = false
) => {
	// allow component rendering if it requires no permissions
	if (componentPermissions.length === 0) {
		return true;
	}

	const commonPermissions = intersect(
		userPermissions,
		componentPermissions
	)?.length;

	const isComponentAccessAllowed = looseCheck
		? commonPermissions > 0 // if any permission matches
		: commonPermissions === componentPermissions.length; // if all permissions are given to user

	return isComponentAccessAllowed;
};
interface Dictionary<T> {
	[Key: string]: T;
}

export function fetchConfigRoute(validPermissions: String[]) {
	const userPermissions = new Set(validPermissions);
	const routesPermissionMap: Dictionary<string[]> = {
		'/config/reports': permissions.config.reports.permission,
		'/config/recon': permissions.config.recon.permission,
		'/config/fetch-closure-amount':
			permissions.config.fetchClosure.fetch_closure_amount,
	};
	let key;
	let value;
	for (key in routesPermissionMap) {
		if (Object.prototype.hasOwnProperty.call(routesPermissionMap, key)) {
			value = routesPermissionMap[key];
			if (userPermissions.has(value[0])) {
				return key;
			}
		}
	}
	return '/';
}

export function fetchPermittedRoute(validPermissions: String[]) {
	const userPermissions = new Set(validPermissions);
	const routesPermissionMap: Dictionary<string[]> = {
		'/loans': permissions.loans.permission,
		'/payments': permissions.payments.permission,
		'/fund-transfer': permissions.fund_transfer.permission,
		'/payouts': permissions.payout.permission,
		'/refunds': permissions.refunds.permission,
		'/config/reports': permissions.config.reports.permission,
		'/config/recon': permissions.config.recon.permission,
		'/config/fetch-closure-amount':
			permissions.config.fetchClosure.fetch_closure_amount,
	};
	let key;
	let value;
	for (key in routesPermissionMap) {
		if (Object.prototype.hasOwnProperty.call(routesPermissionMap, key)) {
			value = routesPermissionMap[key];
			if (userPermissions.has(value[0])) {
				return key;
			}
		}
	}
	return '/';
}
