import React from 'react';

export default function GoogleIconSvg() {
	return (
		<svg
			width='36'
			height='36'
			viewBox='0 0 36 36'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M35.6175 18.405C35.6175 17.22 35.5125 16.095 35.3325 15H18.3825V21.765H28.0875C27.6525 23.985 26.3775 25.86 24.4875 27.135V31.635H30.2775C33.6675 28.5 35.6175 23.88 35.6175 18.405Z'
				fill='#4285F4'
			/>
			<path
				d='M18.3825 36C23.2425 36 27.3075 34.38 30.2775 31.635L24.4875 27.135C22.8675 28.215 20.8125 28.875 18.3825 28.875C13.6875 28.875 9.7125 25.71 8.2875 21.435H2.3175V26.07C5.2725 31.95 11.3475 36 18.3825 36Z'
				fill='#34A853'
			/>
			<path
				d='M8.2875 21.435C7.9125 20.355 7.7175 19.2 7.7175 18C7.7175 16.8 7.9275 15.645 8.2875 14.565V9.92999H2.3175C1.0875 12.36 0.382502 15.09 0.382502 18C0.382502 20.91 1.0875 23.64 2.3175 26.07L8.2875 21.435Z'
				fill='#FBBC05'
			/>
			<path
				d='M18.3825 7.125C21.0375 7.125 23.4075 8.04 25.2825 9.825L30.4125 4.695C27.3075 1.785 23.2425 0 18.3825 0C11.3475 0 5.2725 4.05 2.3175 9.93L8.2875 14.565C9.7125 10.29 13.6875 7.125 18.3825 7.125Z'
				fill='#EA4335'
			/>
		</svg>
	);
}
