import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import OroPaisaRoutes from './routes/index';
import './styles/index.css';
import './styles/datepicker.css';
import EmptyState from './components/EmptyState';

const rootElement = document.getElementById('root');

const currEnv = process.env.REACT_APP_ENV?.toLowerCase();

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],
	tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACKING_RATE) || 0,
	debug: currEnv === 'dev',
	environment: currEnv,
	autoSessionTracking: true,
});

// a simple check if root exist or not
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);
root.render(
	<Sentry.ErrorBoundary
		fallback={<EmptyState containerClassName='h-[100vh]' />}
	>
		<React.StrictMode>
			<OroPaisaRoutes />
		</React.StrictMode>
	</Sentry.ErrorBoundary>
);
