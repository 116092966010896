import useAxios from 'utils/initAxios';
import { getValueFromLocalStorage } from 'utils/local-storage';

export default function verifyToken(isManual: boolean = false) {
	const [, execute] = useAxios(
		{
			baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
			url: '/api/v1/auth/verify-token',
			method: 'POST',
			headers: {
				Authorization: `JWT ${getValueFromLocalStorage('token')}`,
			},
		},
		{ useCache: false, autoCancel: false, manual: isManual }
	);

	return { handleFetchVerifyToken: execute };
}
