import React from 'react';
import { ISvgProps } from './interface';

export default function WarningIconSvg({
	width = '91',
	height = '91',
	className,
}: ISvgProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 91 91'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				d='M39.0154 14.6358L6.90003 68.2499C6.23788 69.3966 5.88753 70.6967 5.88382 72.0208C5.88011 73.3449 6.22318 74.647 6.87889 75.7973C7.53461 76.9477 8.48011 77.9063 9.62134 78.5779C10.7626 79.2494 12.0597 79.6104 13.3838 79.6249H77.6146C78.9387 79.6104 80.2358 79.2494 81.3771 78.5779C82.5183 77.9063 83.4638 76.9477 84.1195 75.7973C84.7752 74.647 85.1183 73.3449 85.1146 72.0208C85.1109 70.6967 84.7605 69.3966 84.0984 68.2499L51.9829 14.6358C51.307 13.5214 50.3553 12.6001 49.2196 11.9607C48.0839 11.3213 46.8025 10.9854 45.4992 10.9854C44.1959 10.9854 42.9145 11.3213 41.7788 11.9607C40.6431 12.6001 39.6914 13.5214 39.0154 14.6358V14.6358Z'
				stroke='url(#paint0_linear_2306_2628)'
				strokeWidth='7.58333'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M45.5 34.125V49.2917'
				stroke='url(#paint1_linear_2306_2628)'
				strokeWidth='7.58333'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M45.5 64.458H45.5379'
				stroke='url(#paint2_linear_2306_2628)'
				strokeWidth='7.58333'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_2306_2628'
					x1='45.4992'
					y1='10.9854'
					x2='45.4992'
					y2='79.6249'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F9CA36' />
					<stop offset='1' stopColor='#F8B830' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_2306_2628'
					x1='46'
					y1='34.125'
					x2='46'
					y2='49.2917'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F9CA36' />
					<stop offset='1' stopColor='#F8B830' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_2306_2628'
					x1='45.519'
					y1='64.458'
					x2='45.519'
					y2='65.458'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F9CA36' />
					<stop offset='1' stopColor='#F8B830' />
				</linearGradient>
			</defs>
		</svg>
	);
}
