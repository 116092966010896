import React from 'react';
import EmptyStateIllustrationsSvg from 'assets/svg/emptyStateIllustrations';

export default function EmptyState({
	containerClassName = 'h-[60vh]',
	illustrationHeight = 'h-50',
	textClassName = 'text-sm font-bold text-center py-10',
}: {
	containerClassName?: string;
	illustrationHeight?: string;
	textClassName?: string;
}) {
	return (
		<div className={`${containerClassName} flex items-center justify-center`}>
			<div className='py-2'>
				<EmptyStateIllustrationsSvg
					className={`${illustrationHeight} mx-auto`}
				/>
				<p className={textClassName}>
					Uh-oh. seems like there are no results to show. <br />
					If you are using filters, try changing the filters may be?
				</p>
			</div>
		</div>
	);
}
