import React from 'react';
import { ISvgProps } from './interface';

interface IDoneStatusProps extends ISvgProps {
	checkMarkColor?: string;
	checkMarkBackgroundColor?: string;
}

export default function DoneStatus({
	width = '15',
	height = '15',
	className,
	checkMarkColor = '#fff',
	checkMarkBackgroundColor = '#5A953E',
}: IDoneStatusProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				d='M7 13.9973C10.866 13.9973 14 10.8653 14 7.00188C14 3.13842 10.866 0.00646973 7 0.00646973C3.13401 0.00646973 0 3.13842 0 7.00188C0 10.8653 3.13401 13.9973 7 13.9973Z'
				fill={checkMarkBackgroundColor}
			/>
			<path
				d='M6.43859 10.7208L3.12891 8.14242L4.06997 6.9347L6.10237 8.51802L9.45669 3.67773L10.7154 4.54967L6.43859 10.7208Z'
				fill={checkMarkColor}
			/>
		</svg>
	);
}
