import { AxiosError } from 'axios';
import { ERROR, PUSH_NOTIFICATION } from 'constants/common/notificationTypes';

// use this function for error handling of apis
export default function errorHandling(err: AxiosError) {
	if (err?.response) {
		const { data } = err.response;
		return {
			type: PUSH_NOTIFICATION,
			payload: {
				id: Math.floor(Math.random() * 100),
				type: ERROR,
				message: data?.message,
			},
		};
	}
	return {
		type: PUSH_NOTIFICATION,
		payload: {
			id: Math.floor(Math.random() * 100),
			type: ERROR,
			message: 'Something went wrong',
		},
	};
}
